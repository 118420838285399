.offers {
	overflow: hidden;
	&__banner {
		background: #121618;
		border-bottom: 1px solid $grey;
		color: $grey;
		font-weight: 800;
		text-transform: uppercase;
		white-space: nowrap;
		overflow: hidden;
		span {
			padding: 0 10px; }
		@include rm(1441) {
			span {
				padding: 0 vw(10px); } }
		@include r(1440) {
			padding: 5px 0; } }
	&__center {
		text-align: center;
		padding-top: 30px;
		position: relative;
		overflow: hidden;
		@include rm(1441) {
			padding-top: vw(30px); } }
	&__bg-text {
		position: absolute;
		@include rm(1441) {
			top: vw(-80px); }
		@include r(1440) {
			top: 50%;
			transform: translateY(-50%); } }
	&__title {
		@include rm(1441) {
			margin-bottom: vw(30px); }
		@include r(1440) {
			margin-bottom: 30px; }
		@include r(640) {
			margin-bottom: 20px; } }
	&__desc {
		@include rm(1441) {
			margin-bottom: vw(12px); }
		@include r(1440) {
			margin-bottom: 12px;
			line-height: 1.4;
			font-size: adft(16, 14);
			padding-left: 15px;
			padding-right: 15px; }
		@include r(479) {
			font-size: 14px; } }
	&__picture {
		position: relative;
		margin: 0 auto;
		overflow: hidden;
		@include rm(1441) {
			height: vw(400px);
			width: vw(692px);
			padding-top: vw(35px); }
		@include r(1440) {
			width: 692px;
			height: 400px;
			padding-top: 35px; }
		@include r(899) {
			width: 70%;
			max-width: 100%;
			height: 45vw; }
		@include r(479) {
			width: 90%;
			height: 58vw; } }
	&__circle {
		width: 100%; }
	&__photo {
		position: absolute;
		top: 0%;
		left: 50%;
		transform: translateX(-50%);
		@include rm(1441) {
			width: vw(527px); }
		@include r(899) {
			width: 90%;
			max-width: 100%; } }
	&__bottom {
		background: $yellow; }
	&__bottom-help {
		text-transform: uppercase;
		color: $light-yellow;
		font-weight: 800;
		line-height: 1;
		white-space: nowrap;
		img {
			pointer-events: none; }
		span {
			padding: 0 10px; }
		@include rm(641) {
			line-height: vw(75px);
			font-size: vw(55px);
			img {
				width: vw(48px);
				margin-left: vw(20px); }
			span {
				padding: 0 vw(10px); } }
		@include r(640) {
			font-size: 23px;
			padding: 3px 0 6px;
			span {
				padding: 0 5px;
				display: inline-flex;
				align-items: center; }
			img {
				width: 23px;
				margin-left: 10px; } } }
	&__info {
		border-top: 1px solid $grey;
		position: relative;
		z-index: 1;
		overflow: hidden;
		@include rm(1441) {
			padding: vw(32px) 0; }
		@include r(1440) {
			padding: 3%; }
		@include r(899) {
			padding: 5%; } }
	&__info-text {
		text-transform: uppercase;
		text-align: center;
		color: $dark-grey;
		font-weight: 800;
		@include rm(1441) {
			max-width: vw(630px);
			margin: 0 auto;
			font-size: vw(20px);
			line-height: vw(27px); }
		@include r(1440) {
			font-size: adft(20, 16);
			line-height: 1.4;
			max-width: 100%; }
		@include r(479) {
			font-size: 16px; } }
	&__bg {
		position: absolute;
		top: -10%;
		left: 0;
		z-index: -1; }
	&__bg-row {
		white-space: nowrap;
		&:nth-of-type(even) {
			margin-left: vw(-75px/2); } }
	&__bg-img {
		margin-right: 10px;
		opacity: .2;
		pointer-events: none;
		@include rm(1441) {
			width: vw(75px); } } }
