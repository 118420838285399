.menu-mobile {
	display: none;
	@include r(899) {
		background: $light-grey;
		padding-bottom: 35px;
		padding-top: 85px;
		overflow: hidden;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 21;
		pointer-events: none;
		opacity: 0;
		display: block;
		transition: opacity .4s cubic-bezier(0.38, 0.005, 0.215, 1);
		transition-delay: .1s;
		&--active {
			pointer-events: auto;
			opacity: 1; }
		@include r(479) {
			padding-top: 65px; } }
	&__wrap {
		display: flex;
		flex-direction: column;
		height: 100%;
		overflow-y: auto;
		justify-content: space-between;
		padding: 0 4%;
		@include r(479) {
			padding: 0 15px; } }
	&__list {
		margin-bottom: 36px;
		display: block; }
	&__link {
		@include nlt {
			margin-bottom: 26px; } }
	&__bottom {
		max-width: 290px;
		text-align: center;
		@include r(479) {
			text-align: center;
			max-width: 100%; } }
	&__btn {
		width: auto;
		margin-bottom: 36px; } }
