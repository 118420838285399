.niches {
	height: 100vh;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	z-index: 20;
	position: relative;
	&--mob {
		display: none; }
	@include r(767) {
		height: auto;
		&--mob {
			display: flex; }
		&--desc {
			opacity: 0;
			pointer-events: none;
			height: 0; } }
	&__center {
		flex: 1;
		display: flex;
		background: $grey;
		position: relative;
		min-height: vwa(396px);
		@include r(767) {
			min-height: 94vw; } }
	&__bg-bottom {
		position: absolute;
		@include rm(1441) {
			bottom: vwa(27px); }
		@include r(1440) {
			bottom: 10%; } }
	&__bg-top {
		position: absolute;
		@include rm(1441) {
			top: vwa(27px); }
		@include r(1440) {
			top: 10%; } }
	&__bg-text {
		font-weight: 800;
		color: transparentize($light-grey, .92);
		pointer-events: none;
		span {
			padding-left: 15px;
			padding-right: 15px; }
		@include rm(1441) {
			font-size: vwa(40px);
			span {
				padding-left: vwa(15px);
				padding-right: vwa(15px); } }
		@include r(1440) {
			font-size: adft(40, 23); }
		@include r(479) {
			font-size: 23px; }
		&--reverse {
			transform: rotate(180deg) translateX(-20px); } }
	&__top {
		background: $yellow;
		@include r(1440) {
			padding: 5px; } }
	&__title {
		font-weight: 800;
		text-transform: uppercase;
		color: $dark-grey;
		text-align: center;
		@include rm(641) {
			font-size: vwa(55px);
			line-height: vwa(85px); } }
	&__bottom {
		background: $yellow;
		position: relative;
		overflow: hidden;
		z-index: 1;
		@include rm(1441) {
			padding-top: vwa(20px);
			padding-bottom: vwa(20px); }
		@include r(1440) {
			padding: 2%;
			text-align: center; }
		@include r(899) {
			padding: 5%; }
		@include r(767) {
			padding-top: 7%; } }
	&__bg {
		position: absolute;
		top: -10%;
		left: 0;
		z-index: -1;
		pointer-events: none; }
	&__bg-row {
		white-space: nowrap;
		&:nth-of-type(even) {
			margin-left: vwa(-75px/2); } }
	&__bg-img {
		margin-right: 10px;
		opacity: .2;
		@include rm(1441) {
			width: vwa(75px); } }
	&__desc {
		color: $dark-grey;
		text-transform: uppercase;
		font-weight: 800;
		text-align: center;
		margin: 0 auto;
		@include rm(1441) {
			margin-bottom: vwa(30px);
			font-size: vwa(20px);
			line-height: vwa(27px); }
		@include r(1440) {
			font-size: adft(20, 16);
			line-height: 1.4;
			max-width: 100%;
			margin-bottom: 30px; }
		@include r(899) {
			br {
				display: none; } }
		@include r(479) {
			font-size: 16px;
			margin-bottom: 20px; } }
	&__btn {
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
		br {
			display: none; }
		@include rm(1441) {
			max-width: vwa(440px);
			font-size: vwa(33px);
			line-height: vwa(45px);
			padding: vwa(5px) vwa(20px);
			.fico {
				font-size: vwa(22px);
				margin-left: vwa(10px); } }
		@include r(1440) {
			max-width: inherit;
			width: auto;
			display: inline-flex; }
		@include r(479) {
			line-height: 1.2;
			br {
				display: block; } } }
	&__slider {
		@include r(767) {
			position: absolute;
			top: 0;
			bottom: 0;
			width: 100%;
			.slick-list, .slick-track {
				height: 100%; } } } }


.niche {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	position: relative;
	overflow: hidden;
	&--1 {
		.niche__img {
			top: 0%;
			width: vwa(768px); } }
	&--2 {
		.niche__img {
			bottom: -27%;
			width: vwa(854px); } }
	&--3 {
		.niche__img {
			bottom: -30%;
			width: vwa(883px); } }
	&--4 {
		.niche__img {
			bottom: -34%;
			width: vwa(782px); } }
	&--5 {
		.niche__img {
			bottom: -16%;
			width: vwa(663px); } }
	@include r(1199) {
		&--1 {
			.niche__img {
				top: 2%;
				width: vwa(1000px); } }
		&--2 {
			.niche__img {
				bottom: -27%;
				width: vwa(1200px); } }
		&--3 {
			.niche__img {
				bottom: -20%;
				width: vwa(1300px); } }
		&--4 {
			.niche__img {
				bottom: -30%;
				width: vwa(1200px); } }
		&--5 {
			.niche__img {
				bottom: -16%;
				width: vwa(1000px); } } }
	@include r(899) {
		.niche__img {
			position: absolute;
			bottom: auto;
			top: 50%;
			transform: translateY(-50%); }
		&--1, &--2, &--4, &--5 {
			.niche__img {
				width: vwa(1500px); } }
		&--2, &--4, &--5 {
			.niche__img {
				top: 60%; } }
		&--3 {
			.niche__img {
				width: vwa(1800px); } } }
	@include r(767) {
		.niche__img {
			top: auto;
			left: 50%;
			transform: translateX(-50%); }
		&--1 {
			.niche__img {
				width: 90%;
				top: -17%; } }
		&--2 {
			.niche__img {
				width: 90%;
				bottom: -5%; } }
		&--3 {
			.niche__img {
				width: 100%;
				bottom: 2%; } }
		&--4 {
			.niche__img {
				width: 90%;
				bottom: -7%; } }
		&--5 {
			.niche__img {
				width: 90%;
				bottom: 2%; } } }
	@include r(479) {
		&--1 {
			.niche__img {
				width: 114%;
				top: -40%;
				max-width: inherit; } }
		&--2 {
			.niche__img {
				width: 110%;
				bottom: -13%;
				max-width: inherit; } }
		&--3 {
			.niche__img {
				width: 124%;
				max-width: inherit; } }
		&--4 {
			.niche__img {
				width: 133%;
				max-width: inherit;
				bottom: -27%;
				left: 32%; } }
		&--5 {
			.niche__img {
				width: 119%;
				max-width: inherit;
				bottom: -6%; } } }
	@include r(767) {
		overflow: visible;
		position: static;
		width: auto;
		height: auto; }
	&__bg-text {
		text-transform: uppercase;
		font-weight: 800;
		color: $light-grey;
		line-height: 1.1;
		opacity: .4;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: -1;
		pointer-events: none;
		span {
			padding: 0 20px; }
		@include rm(1441) {
			font-size: vwa(193px);
			span {
				padding: 0 vwa(20px); } }
		@include r(1440) {
			font-size: adft(193, 100); }
		@include r(479) {
			font-size: 100px; } }
	&__img {
		position: relative;
		pointer-events: none; } }


.slick-dots {
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 11;
	position: relative; }

#niches {
	@include r(767) {
		.pin-spacer {
			display: none !important; } } }
