html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=); }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

@font-face {
  font-family: "fico";
  src: url("../fonts/icons/fico.eot");
  src: url("../fonts/icons/fico.eot?#iefix") format("eot"), url("../fonts/icons/fico.woff2") format("woff2"), url("../fonts/icons/fico.woff") format("woff"), url("../fonts/icons/fico.ttf") format("truetype"), url("../fonts/icons/fico.svg#fico") format("svg"); }

.fico:before {
  font-family: "fico";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  /* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
  text-decoration: none;
  text-transform: none; }

.fico-arrow:before {
  content: "\E001"; }

.fico-next-arrow:before {
  content: "\E002"; }

.fico-prev-arrow:before {
  content: "\E003"; }

.fico-scull:before {
  content: "\E004"; }

/* open-sans-regular - cyrillic_cyrillic-ext_latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/open-sans-v34-cyrillic_cyrillic-ext_latin_latin-ext-regular.woff2") format("woff2"), url("../fonts/open-sans-v34-cyrillic_cyrillic-ext_latin_latin-ext-regular.woff") format("woff");
  /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* open-sans-800 - cyrillic_cyrillic-ext_latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/open-sans-v34-cyrillic_cyrillic-ext_latin_latin-ext-800.woff2") format("woff2"), url("../fonts/open-sans-v34-cyrillic_cyrillic-ext_latin_latin-ext-800.woff") format("woff");
  /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* open-sans-700 - cyrillic_cyrillic-ext_latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/open-sans-v34-cyrillic_cyrillic-ext_latin_latin-ext-700.woff2") format("woff2"), url("../fonts/open-sans-v34-cyrillic_cyrillic-ext_latin_latin-ext-700.woff") format("woff");
  /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

body {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: #EDEDED;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscal;
  text-size-adjust: none;
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  background: #1D2022;
  --aspect: 1; }

html {
  overflow-x: hidden;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

::-ms-clear {
  display: none; }

*:focus, *:visited, *:active, *:hover {
  outline: 0 !important; }

a, a:focus, a:visited, a:active, a:hover {
  outline: 0 !important; }

*::-moz-focus-inner {
  border: 0;
  outline: none; }

img {
  max-width: 100%; }

ul, li {
  list-style: none; }

.fico {
  display: inline-block; }
  .fico::before {
    display: block; }

.container {
  width: 1270px;
  margin: 0 auto; }

.menu {
  font-weight: 700;
  display: flex;
  align-items: center; }
  .menu__link {
    transition: color .3s;
    text-decoration: none;
    color: rgba(29, 32, 34, 0.4);
    white-space: nowrap; }
    .menu__link:hover {
      color: #1D2022; }
    @media screen and (max-width: 1199px) {
      .menu__link {
        font-size: 14px; } }
    @media screen and (max-width: 899px) {
      .menu__link {
        font-size: 16px;
        line-height: 22px;
        color: #1D2022; } }

.language {
  font-weight: 700; }
  .language__link {
    color: rgba(29, 32, 34, 0.4);
    text-decoration: none; }
    .language__link:not(:last-of-type) {
      margin-right: 20px; }
    .language__link.active {
      color: #1D2022; }
    @media screen and (min-width: 1441px) {
      .language__link:not(:last-of-type) {
        margin-right: 1.38889vw; } }
    @media screen and (max-width: 1199px) {
      .language__link:not(:last-of-type) {
        margin-right: 10px; } }
    @media screen and (max-width: 899px) {
      .language__link {
        font-size: 16px; }
        .language__link:not(:last-of-type) {
          margin-right: 35px; } }

.btn {
  display: block;
  text-decoration: none;
  outline: none;
  max-width: 100%;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  background: #F16122;
  border-radius: 5px;
  font-weight: 800;
  padding: 3px 7px;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  line-height: 22px;
  transition: all .3s;
  white-space: nowrap; }
  .btn--big {
    font-size: 33px;
    line-height: 45px;
    padding: 5px 20px; }
  .btn .fico {
    color: #ffffff;
    margin-left: 10px;
    font-size: 22px; }
  .btn:hover {
    background: #C23B00; }
  @media screen and (max-width: 1440px) {
    .btn--big {
      font-size: calc(23px + 10 * ((100vw - 479px) / 961));
      line-height: 1.4; } }
  @media screen and (max-width: 479px) {
    .btn--big {
      font-size: 23px; } }

@media screen and (min-width: 768px) {
  .section {
    display: flex;
    justify-content: center; }
    .section__col {
      width: 50%; }
      .section__col:not(:last-of-type) {
        border-right: 1px solid #3E4448; } }

@media screen and (min-width: 1441px) {
  .section__col:first-of-type {
    padding-left: 5.69444vw; } }

.title {
  font-weight: 800;
  line-height: 110%;
  text-transform: uppercase;
  color: #EDEDED; }
  @media screen and (min-width: 1441px) {
    .title {
      font-size: 2.77778vw; } }
  @media screen and (max-width: 1440px) {
    .title {
      font-size: calc(23px + 15 * ((100vw - 479px) / 961)); } }
  @media screen and (max-width: 1199px) {
    .title {
      font-size: 26px; } }
  @media screen and (max-width: 899px) {
    .title {
      font-size: 23px; } }
  @media screen and (max-width: 767px) {
    .title {
      font-size: 26px; } }
  @media screen and (max-width: 479px) {
    .title {
      font-size: 23px; } }

@media screen and (min-width: 641px) {
  .text-big {
    font-size: 3.81944vw;
    line-height: 5.90278vw; } }

@media screen and (max-width: 1440px) {
  .text-big {
    font-size: calc(23px + 15 * ((100vw - 479px) / 961)); } }

@media screen and (max-width: 479px) {
  .text-big {
    font-size: 23px; } }

.big-marquee {
  font-weight: 800;
  line-height: 1;
  color: rgba(242, 174, 46, 0.05);
  pointer-events: none;
  font-size: 623px; }
  @media screen and (min-width: 1441px) {
    .big-marquee {
      font-size: 43.26389vw; } }
  @media screen and (max-width: 1440px) {
    .big-marquee {
      font-size: calc(156px + 467 * ((100vw - 479px) / 961)); } }
  @media screen and (max-width: 479px) {
    .big-marquee {
      font-size: 156px; } }

.letters {
  pointer-events: none; }
  .letters__row {
    opacity: 0;
    transform: translateY(80px) scale(1.4);
    white-space: nowrap;
    font-weight: 800;
    line-height: 95%;
    transform-origin: 33% 0;
    color: rgba(242, 174, 46, 0.05); }
    .letters__row.mob {
      display: none; }
    @media screen and (min-width: 1441px) {
      .letters__row {
        font-size: 2.77778vw; } }
    @media screen and (max-width: 1440px) {
      .letters__row {
        font-size: calc(23px + 15 * ((100vw - 479px) / 961)); } }
    @media screen and (max-width: 1199px) {
      .letters__row.mob {
        display: block; } }
    @media screen and (max-width: 479px) {
      .letters__row {
        font-size: 23px; } }

.footer {
  padding: 2.77778vw 15px;
  text-align: center;
  border-top: 1px solid #3E4448; }
  .footer__text {
    color: #3E4448;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 20px; }

.slick-arrow {
  position: absolute;
  bottom: -21px;
  cursor: pointer;
  z-index: 2;
  width: 42px;
  height: 42px;
  background: #EDEDED;
  border: none;
  display: flex;
  align-items: center; }
  .slick-arrow.slick-prev, .slick-arrow.slick-next {
    font-size: 22px; }
  .slick-arrow.slick-prev {
    left: 0;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px; }
  .slick-arrow.slick-next {
    right: 0;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px; }

.header {
  background: #EDEDED;
  z-index: 13;
  position: fixed;
  top: 0;
  left: 0;
  right: 0; }
  @media screen and (min-width: 1441px) {
    .header {
      padding: 10px 4.16667vw; } }
  @media screen and (max-width: 1440px) {
    .header {
      padding: 10px 4%; } }
  @media screen and (max-width: 899px) {
    .header {
      z-index: 22; } }
  @media screen and (max-width: 479px) {
    .header {
      padding: 5px 15px; } }
  .header__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .header__logo {
    width: 103px; }
    @media screen and (max-width: 1199px) {
      .header__logo {
        width: 90px; } }
    @media screen and (max-width: 479px) {
      .header__logo {
        width: 65px; } }
  .header__right {
    display: flex;
    align-items: center; }
    @media screen and (max-width: 899px) {
      .header__right {
        display: none; } }
  .header__menu {
    margin-right: 36px;
    flex: 1; }
    @media screen and (min-width: 1441px) {
      .header__menu {
        margin-right: 2.5vw; } }
    @media screen and (max-width: 1199px) {
      .header__menu {
        margin-right: 17px; } }
  .header__link:not(:last-of-type) {
    margin-right: 38px; }
  @media screen and (min-width: 1441px) {
    .header__link:not(:last-of-type) {
      margin-right: 2.63889vw; } }
  @media screen and (max-width: 1199px) {
    .header__link:not(:last-of-type) {
      margin-right: 17px; } }
  .header__btn {
    margin-right: 36px;
    width: 135px; }
    @media screen and (min-width: 1441px) {
      .header__btn {
        margin-right: 2.5vw; } }
    @media screen and (max-width: 1199px) {
      .header__btn {
        margin-right: 17px; } }

.menu-btn {
  display: none; }
  @media screen and (max-width: 899px) {
    .menu-btn {
      cursor: pointer;
      display: block;
      border: 1px solid #1D2022;
      border-radius: 3px;
      color: #1D2022;
      text-align: center;
      font-weight: 700;
      font-size: 16px;
      padding: 4px 11px; } }
  @media screen and (max-width: 479px) {
    .menu-btn {
      font-size: 12px;
      line-height: 1.2;
      padding: 1px 7px; } }

.menu-mobile {
  display: none; }
  @media screen and (max-width: 899px) {
    .menu-mobile {
      background: #EDEDED;
      padding-bottom: 35px;
      padding-top: 85px;
      overflow: hidden;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 21;
      pointer-events: none;
      opacity: 0;
      display: block;
      transition: opacity 0.4s cubic-bezier(0.38, 0.005, 0.215, 1);
      transition-delay: .1s; }
      .menu-mobile--active {
        pointer-events: auto;
        opacity: 1; } }
  @media screen and (max-width: 899px) and (max-width: 479px) {
    .menu-mobile {
      padding-top: 65px; } }
  .menu-mobile__wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    justify-content: space-between;
    padding: 0 4%; }
    @media screen and (max-width: 479px) {
      .menu-mobile__wrap {
        padding: 0 15px; } }
  .menu-mobile__list {
    margin-bottom: 36px;
    display: block; }
  .menu-mobile__link:not(:last-of-type) {
    margin-bottom: 26px; }
  .menu-mobile__bottom {
    max-width: 290px;
    text-align: center; }
    @media screen and (max-width: 479px) {
      .menu-mobile__bottom {
        text-align: center;
        max-width: 100%; } }
  .menu-mobile__btn {
    width: auto;
    margin-bottom: 36px; }

.promo {
  overflow-x: hidden;
  padding-top: 52px; }
  @media screen and (max-width: 899px) {
    .promo {
      padding-top: 48px; } }
  @media screen and (max-width: 767px) {
    .promo {
      display: flex;
      flex-direction: column-reverse;
      position: relative; } }
  @media screen and (max-width: 479px) {
    .promo {
      padding-top: 31px; } }
  .promo__left {
    position: relative;
    overflow: hidden; }
    @media screen and (min-width: 1441px) {
      .promo__left {
        padding: 10.41667vw 0;
        padding-right: 9.72222vw; } }
    @media screen and (max-width: 1440px) {
      .promo__left {
        padding: 9% 9% 9% 4%; } }
    @media screen and (max-width: 767px) {
      .promo__left {
        padding: 0% 5% 5% 5%;
        position: static; } }
  .promo__bg-text {
    position: absolute; }
    .promo__bg-text span {
      padding: 0 30px; }
    @media screen and (min-width: 1441px) {
      .promo__bg-text {
        top: -2.77778vw; } }
    @media screen and (max-width: 1440px) {
      .promo__bg-text {
        top: -0.69444vw; } }
    @media screen and (max-width: 767px) {
      .promo__bg-text {
        position: static;
        transform: none; } }
  .promo__title {
    margin-bottom: 2.08333vw; }
    @media screen and (max-width: 767px) {
      .promo__title {
        margin-bottom: 0;
        position: absolute;
        text-align: center;
        top: 90px;
        z-index: 1;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
        margin: 0 auto; } }
    @media screen and (max-width: 479px) {
      .promo__title {
        width: 300px;
        top: 67px; } }
  .promo__desc {
    line-height: 1.4;
    margin-bottom: 13px; }
    @media screen and (min-width: 1441px) {
      .promo__desc {
        font-size: 1.11111vw;
        line-height: 1.52778vw;
        margin-bottom: 0.90278vw; } }
    @media screen and (max-width: 1440px) {
      .promo__desc {
        line-height: 1.4;
        font-size: calc(14px + 2 * ((100vw - 479px) / 961)); } }
    @media screen and (max-width: 479px) {
      .promo__desc {
        font-size: 14px; } }
  .promo__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    position: relative; }
    @media screen and (max-width: 479px) {
      .promo__right {
        min-height: 500px; } }
  .promo__bg {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    opacity: .3; }
    @media screen and (min-width: 1441px) {
      .promo__bg {
        top: 2.77778vw; } }
    @media screen and (max-width: 1440px) {
      .promo__bg {
        top: 2%; } }
  .promo__bg-row {
    white-space: nowrap; }
    @media screen and (min-width: 1441px) {
      .promo__bg-row:nth-of-type(even) {
        margin-left: -2.60417vw; } }
    @media screen and (max-width: 1440px) {
      .promo__bg-row:nth-of-type(even) {
        margin-left: -37.5px; } }
    @media screen and (max-width: 479px) {
      .promo__bg-row:nth-of-type(even) {
        margin-left: -21.5px; } }
  .promo__bg-img {
    opacity: 0;
    transform: scale(0.9); }
    @media screen and (min-width: 1441px) {
      .promo__bg-img {
        width: 5.20833vw; }
        .promo__bg-img:not(:last-of-type) {
          margin-right: 0.34722vw; } }
    @media screen and (max-width: 1440px) {
      .promo__bg-img {
        width: 75px; }
        .promo__bg-img:not(:last-of-type) {
          margin-right: 5px; } }
    @media screen and (max-width: 479px) {
      .promo__bg-img {
        width: 43px; } }
  .promo__picture {
    position: relative;
    margin: 0 auto; }
    @media screen and (min-width: 1441px) {
      .promo__picture {
        width: 33.68056vw; } }
    @media screen and (max-width: 1440px) {
      .promo__picture {
        width: 64%;
        max-width: 100%; } }
    @media screen and (max-width: 767px) {
      .promo__picture {
        top: 100px;
        width: 500px; } }
    @media screen and (max-width: 640px) {
      .promo__picture {
        width: 420px; } }
    @media screen and (max-width: 479px) {
      .promo__picture {
        width: 290px;
        top: 80px; } }
  .promo__circle {
    width: 100%; }
  .promo__photo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    @media screen and (min-width: 1440px) {
      .promo__photo {
        width: 31.11111vw; } }
    @media screen and (max-width: 1440px) {
      .promo__photo {
        width: 100%;
        max-width: 100%; } }
  .promo__btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 35%); }
    @media screen and (min-width: 1441px) {
      .promo__btn {
        max-width: 20.13889vw;
        font-size: 2.29167vw;
        line-height: 3.125vw;
        padding: 0.34722vw 1.38889vw; } }
    @media screen and (max-width: 1440px) {
      .promo__btn {
        max-width: inherit;
        width: auto; } }
  .promo__label {
    color: #3E4448;
    font-weight: 800;
    background: #121618;
    line-height: 1.2;
    padding: 5px;
    white-space: nowrap;
    text-transform: uppercase; }
    .promo__label em {
      text-transform: capitalize; }
    .promo__label--top {
      border-bottom: 1px solid #3E4448; }
    .promo__label--bottom {
      border-top: 1px solid #3E4448; }
      .promo__label--bottom span {
        transform: translateX(-400px);
        padding: 0 10px; }
    .promo__label span {
      display: inline-block;
      padding: 0 10px; }
    @media screen and (min-width: 1441px) {
      .promo__label {
        font-size: 1.38889vw;
        padding: 0.34722vw; }
        .promo__label span {
          transform: translateX(-27.77778vw); } }
    @media screen and (max-width: 1440px) {
      .promo__label {
        font-size: calc(16px + 4 * ((100vw - 479px) / 961)); } }
    @media screen and (max-width: 767px) {
      .promo__label {
        z-index: 1; }
        .promo__label--bottom {
          border-bottom: 1px solid #3E4448; } }

.banner-info {
  background: #F2AE2E;
  color: #FFF9BB;
  font-weight: 800;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden; }
  @media screen and (max-width: 640px) {
    .banner-info {
      padding: 5px 0; } }
  .banner-info__text {
    padding-left: 10px;
    padding-right: 10px; }
    @media screen and (min-width: 1441px) {
      .banner-info__text {
        padding-left: 0.69444vw;
        padding-right: 0.69444vw; } }

.statistics {
  overflow: hidden; }
  @media screen and (max-width: 767px) {
    .statistics {
      position: relative;
      padding-bottom: 530px; } }
  @media screen and (max-width: 479px) {
    .statistics {
      padding-bottom: 470px; } }
  .statistics__title span {
    text-transform: capitalize; }
  @media screen and (max-width: 767px) {
    .statistics__title {
      line-height: 120%; } }
  .statistics__left {
    position: relative;
    overflow: hidden;
    background: #1D2022;
    z-index: 1; }
    .statistics__left::before {
      content: '';
      display: block;
      position: absolute;
      background: url("../img/svg/scull.svg") no-repeat center;
      background-size: 100%;
      z-index: -1;
      transform: rotate(90deg);
      opacity: .2; }
    @media screen and (min-width: 1441px) {
      .statistics__left {
        padding-top: 5.27778vw;
        padding-bottom: 7.84722vw;
        padding-right: 2.22222vw; }
        .statistics__left::before {
          top: -2.77778vw;
          left: -0.69444vw;
          width: 32.84722vw;
          height: 29.30556vw; } }
    @media screen and (max-width: 1440px) {
      .statistics__left {
        padding: 5% 2% 5% 4%; }
        .statistics__left::before {
          top: -2.77778vw;
          left: -0.69444vw;
          width: 32.84722vw;
          height: 29.30556vw; } }
    @media screen and (max-width: 767px) {
      .statistics__left {
        overflow: hidden;
        padding: 5%;
        border-bottom: 1px solid #3E4448; }
        .statistics__left::before {
          width: 271px;
          height: 263px;
          top: -50px;
          left: -10px; } }
  .statistics__right--mob {
    display: none; }
  @media screen and (max-width: 1440px) {
    .statistics__right {
      position: relative;
      left: -30px; } }
  @media screen and (max-width: 767px) {
    .statistics__right {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      text-align: center; }
      .statistics__right--desc {
        display: none; }
      .statistics__right--mob {
        display: block; } }
  .statistics__row {
    color: #F2AE2E;
    text-transform: uppercase;
    font-weight: 800;
    white-space: nowrap;
    margin-left: -2%;
    line-height: 107.5%; }
    .statistics__row span {
      opacity: 0.05; }
    .statistics__row:nth-of-type(even) {
      margin-left: -3%; }
    @media screen and (min-width: 1441px) {
      .statistics__row {
        font-size: 2.77778vw; } }
    @media screen and (max-width: 1440px) {
      .statistics__row {
        font-size: calc(23px + 15 * ((100vw - 479px) / 961)); } }
    @media screen and (max-width: 1199px) {
      .statistics__row {
        font-size: 26px; } }
    @media screen and (max-width: 899px) {
      .statistics__row {
        font-size: 23px; } }
    @media screen and (max-width: 767px) {
      .statistics__row {
        font-size: 26px; }
        .statistics__row:nth-of-type(even) {
          margin-left: 0; }
        .statistics__row span.statistics__item {
          color: #EDEDED;
          opacity: 1; } }
    @media screen and (max-width: 479px) {
      .statistics__row {
        font-size: 23px; } }

.niches {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 20;
  position: relative; }
  .niches--mob {
    display: none; }
  @media screen and (max-width: 767px) {
    .niches {
      height: auto; }
      .niches--mob {
        display: flex; }
      .niches--desc {
        opacity: 0;
        pointer-events: none;
        height: 0; } }
  .niches__center {
    flex: 1;
    display: flex;
    background: #3E4448;
    position: relative;
    min-height: calc(27.5vw * var(--aspect)); }
    @media screen and (max-width: 767px) {
      .niches__center {
        min-height: 94vw; } }
  .niches__bg-bottom {
    position: absolute; }
    @media screen and (min-width: 1441px) {
      .niches__bg-bottom {
        bottom: calc(1.875vw * var(--aspect)); } }
    @media screen and (max-width: 1440px) {
      .niches__bg-bottom {
        bottom: 10%; } }
  .niches__bg-top {
    position: absolute; }
    @media screen and (min-width: 1441px) {
      .niches__bg-top {
        top: calc(1.875vw * var(--aspect)); } }
    @media screen and (max-width: 1440px) {
      .niches__bg-top {
        top: 10%; } }
  .niches__bg-text {
    font-weight: 800;
    color: rgba(237, 237, 237, 0.08);
    pointer-events: none; }
    .niches__bg-text span {
      padding-left: 15px;
      padding-right: 15px; }
    @media screen and (min-width: 1441px) {
      .niches__bg-text {
        font-size: calc(2.77778vw * var(--aspect)); }
        .niches__bg-text span {
          padding-left: calc(1.04167vw * var(--aspect));
          padding-right: calc(1.04167vw * var(--aspect)); } }
    @media screen and (max-width: 1440px) {
      .niches__bg-text {
        font-size: calc(23px + 17 * ((100vw - 479px) / 961)); } }
    @media screen and (max-width: 479px) {
      .niches__bg-text {
        font-size: 23px; } }
    .niches__bg-text--reverse {
      transform: rotate(180deg) translateX(-20px); }
  .niches__top {
    background: #F2AE2E; }
    @media screen and (max-width: 1440px) {
      .niches__top {
        padding: 5px; } }
  .niches__title {
    font-weight: 800;
    text-transform: uppercase;
    color: #1D2022;
    text-align: center; }
    @media screen and (min-width: 641px) {
      .niches__title {
        font-size: calc(3.81944vw * var(--aspect));
        line-height: calc(5.90278vw * var(--aspect)); } }
  .niches__bottom {
    background: #F2AE2E;
    position: relative;
    overflow: hidden;
    z-index: 1; }
    @media screen and (min-width: 1441px) {
      .niches__bottom {
        padding-top: calc(1.38889vw * var(--aspect));
        padding-bottom: calc(1.38889vw * var(--aspect)); } }
    @media screen and (max-width: 1440px) {
      .niches__bottom {
        padding: 2%;
        text-align: center; } }
    @media screen and (max-width: 899px) {
      .niches__bottom {
        padding: 5%; } }
    @media screen and (max-width: 767px) {
      .niches__bottom {
        padding-top: 7%; } }
  .niches__bg {
    position: absolute;
    top: -10%;
    left: 0;
    z-index: -1;
    pointer-events: none; }
  .niches__bg-row {
    white-space: nowrap; }
    .niches__bg-row:nth-of-type(even) {
      margin-left: calc(-2.60417vw * var(--aspect)); }
  .niches__bg-img {
    margin-right: 10px;
    opacity: .2; }
    @media screen and (min-width: 1441px) {
      .niches__bg-img {
        width: calc(5.20833vw * var(--aspect)); } }
  .niches__desc {
    color: #1D2022;
    text-transform: uppercase;
    font-weight: 800;
    text-align: center;
    margin: 0 auto; }
    @media screen and (min-width: 1441px) {
      .niches__desc {
        margin-bottom: calc(2.08333vw * var(--aspect));
        font-size: calc(1.38889vw * var(--aspect));
        line-height: calc(1.875vw * var(--aspect)); } }
    @media screen and (max-width: 1440px) {
      .niches__desc {
        font-size: calc(16px + 4 * ((100vw - 479px) / 961));
        line-height: 1.4;
        max-width: 100%;
        margin-bottom: 30px; } }
    @media screen and (max-width: 899px) {
      .niches__desc br {
        display: none; } }
    @media screen and (max-width: 479px) {
      .niches__desc {
        font-size: 16px;
        margin-bottom: 20px; } }
  .niches__btn {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center; }
    .niches__btn br {
      display: none; }
    @media screen and (min-width: 1441px) {
      .niches__btn {
        max-width: calc(30.55556vw * var(--aspect));
        font-size: calc(2.29167vw * var(--aspect));
        line-height: calc(3.125vw * var(--aspect));
        padding: calc(0.34722vw * var(--aspect)) calc(1.38889vw * var(--aspect)); }
        .niches__btn .fico {
          font-size: calc(1.52778vw * var(--aspect));
          margin-left: calc(0.69444vw * var(--aspect)); } }
    @media screen and (max-width: 1440px) {
      .niches__btn {
        max-width: inherit;
        width: auto;
        display: inline-flex; } }
    @media screen and (max-width: 479px) {
      .niches__btn {
        line-height: 1.2; }
        .niches__btn br {
          display: block; } }
  @media screen and (max-width: 767px) {
    .niches__slider {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%; }
      .niches__slider .slick-list, .niches__slider .slick-track {
        height: 100%; } }

.niche {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  overflow: hidden; }
  .niche--1 .niche__img {
    top: 0%;
    width: calc(53.33333vw * var(--aspect)); }
  .niche--2 .niche__img {
    bottom: -27%;
    width: calc(59.30556vw * var(--aspect)); }
  .niche--3 .niche__img {
    bottom: -30%;
    width: calc(61.31944vw * var(--aspect)); }
  .niche--4 .niche__img {
    bottom: -34%;
    width: calc(54.30556vw * var(--aspect)); }
  .niche--5 .niche__img {
    bottom: -16%;
    width: calc(46.04167vw * var(--aspect)); }
  @media screen and (max-width: 1199px) {
    .niche--1 .niche__img {
      top: 2%;
      width: calc(69.44444vw * var(--aspect)); }
    .niche--2 .niche__img {
      bottom: -27%;
      width: calc(83.33333vw * var(--aspect)); }
    .niche--3 .niche__img {
      bottom: -20%;
      width: calc(90.27778vw * var(--aspect)); }
    .niche--4 .niche__img {
      bottom: -30%;
      width: calc(83.33333vw * var(--aspect)); }
    .niche--5 .niche__img {
      bottom: -16%;
      width: calc(69.44444vw * var(--aspect)); } }
  @media screen and (max-width: 899px) {
    .niche .niche__img {
      position: absolute;
      bottom: auto;
      top: 50%;
      transform: translateY(-50%); }
    .niche--1 .niche__img, .niche--2 .niche__img, .niche--4 .niche__img, .niche--5 .niche__img {
      width: calc(104.16667vw * var(--aspect)); }
    .niche--2 .niche__img, .niche--4 .niche__img, .niche--5 .niche__img {
      top: 60%; }
    .niche--3 .niche__img {
      width: calc(125vw * var(--aspect)); } }
  @media screen and (max-width: 767px) {
    .niche .niche__img {
      top: auto;
      left: 50%;
      transform: translateX(-50%); }
    .niche--1 .niche__img {
      width: 90%;
      top: -17%; }
    .niche--2 .niche__img {
      width: 90%;
      bottom: -5%; }
    .niche--3 .niche__img {
      width: 100%;
      bottom: 2%; }
    .niche--4 .niche__img {
      width: 90%;
      bottom: -7%; }
    .niche--5 .niche__img {
      width: 90%;
      bottom: 2%; } }
  @media screen and (max-width: 479px) {
    .niche--1 .niche__img {
      width: 114%;
      top: -40%;
      max-width: inherit; }
    .niche--2 .niche__img {
      width: 110%;
      bottom: -13%;
      max-width: inherit; }
    .niche--3 .niche__img {
      width: 124%;
      max-width: inherit; }
    .niche--4 .niche__img {
      width: 133%;
      max-width: inherit;
      bottom: -27%;
      left: 32%; }
    .niche--5 .niche__img {
      width: 119%;
      max-width: inherit;
      bottom: -6%; } }
  @media screen and (max-width: 767px) {
    .niche {
      overflow: visible;
      position: static;
      width: auto;
      height: auto; } }
  .niche__bg-text {
    text-transform: uppercase;
    font-weight: 800;
    color: #EDEDED;
    line-height: 1.1;
    opacity: .4;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    pointer-events: none; }
    .niche__bg-text span {
      padding: 0 20px; }
    @media screen and (min-width: 1441px) {
      .niche__bg-text {
        font-size: calc(13.40278vw * var(--aspect)); }
        .niche__bg-text span {
          padding: 0 calc(1.38889vw * var(--aspect)); } }
    @media screen and (max-width: 1440px) {
      .niche__bg-text {
        font-size: calc(100px + 93 * ((100vw - 479px) / 961)); } }
    @media screen and (max-width: 479px) {
      .niche__bg-text {
        font-size: 100px; } }
  .niche__img {
    position: relative;
    pointer-events: none; }

.slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
  position: relative; }

@media screen and (max-width: 767px) {
  #niches .pin-spacer {
    display: none !important; } }

.offers {
  overflow: hidden; }
  .offers__banner {
    background: #121618;
    border-bottom: 1px solid #3E4448;
    color: #3E4448;
    font-weight: 800;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden; }
    .offers__banner span {
      padding: 0 10px; }
    @media screen and (min-width: 1441px) {
      .offers__banner span {
        padding: 0 0.69444vw; } }
    @media screen and (max-width: 1440px) {
      .offers__banner {
        padding: 5px 0; } }
  .offers__center {
    text-align: center;
    padding-top: 30px;
    position: relative;
    overflow: hidden; }
    @media screen and (min-width: 1441px) {
      .offers__center {
        padding-top: 2.08333vw; } }
  .offers__bg-text {
    position: absolute; }
    @media screen and (min-width: 1441px) {
      .offers__bg-text {
        top: -5.55556vw; } }
    @media screen and (max-width: 1440px) {
      .offers__bg-text {
        top: 50%;
        transform: translateY(-50%); } }
  @media screen and (min-width: 1441px) {
    .offers__title {
      margin-bottom: 2.08333vw; } }
  @media screen and (max-width: 1440px) {
    .offers__title {
      margin-bottom: 30px; } }
  @media screen and (max-width: 640px) {
    .offers__title {
      margin-bottom: 20px; } }
  @media screen and (min-width: 1441px) {
    .offers__desc {
      margin-bottom: 0.83333vw; } }
  @media screen and (max-width: 1440px) {
    .offers__desc {
      margin-bottom: 12px;
      line-height: 1.4;
      font-size: calc(14px + 2 * ((100vw - 479px) / 961));
      padding-left: 15px;
      padding-right: 15px; } }
  @media screen and (max-width: 479px) {
    .offers__desc {
      font-size: 14px; } }
  .offers__picture {
    position: relative;
    margin: 0 auto;
    overflow: hidden; }
    @media screen and (min-width: 1441px) {
      .offers__picture {
        height: 27.77778vw;
        width: 48.05556vw;
        padding-top: 2.43056vw; } }
    @media screen and (max-width: 1440px) {
      .offers__picture {
        width: 692px;
        height: 400px;
        padding-top: 35px; } }
    @media screen and (max-width: 899px) {
      .offers__picture {
        width: 70%;
        max-width: 100%;
        height: 45vw; } }
    @media screen and (max-width: 479px) {
      .offers__picture {
        width: 90%;
        height: 58vw; } }
  .offers__circle {
    width: 100%; }
  .offers__photo {
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translateX(-50%); }
    @media screen and (min-width: 1441px) {
      .offers__photo {
        width: 36.59722vw; } }
    @media screen and (max-width: 899px) {
      .offers__photo {
        width: 90%;
        max-width: 100%; } }
  .offers__bottom {
    background: #F2AE2E; }
  .offers__bottom-help {
    text-transform: uppercase;
    color: #FFF9BB;
    font-weight: 800;
    line-height: 1;
    white-space: nowrap; }
    .offers__bottom-help img {
      pointer-events: none; }
    .offers__bottom-help span {
      padding: 0 10px; }
    @media screen and (min-width: 641px) {
      .offers__bottom-help {
        line-height: 5.20833vw;
        font-size: 3.81944vw; }
        .offers__bottom-help img {
          width: 3.33333vw;
          margin-left: 1.38889vw; }
        .offers__bottom-help span {
          padding: 0 0.69444vw; } }
    @media screen and (max-width: 640px) {
      .offers__bottom-help {
        font-size: 23px;
        padding: 3px 0 6px; }
        .offers__bottom-help span {
          padding: 0 5px;
          display: inline-flex;
          align-items: center; }
        .offers__bottom-help img {
          width: 23px;
          margin-left: 10px; } }
  .offers__info {
    border-top: 1px solid #3E4448;
    position: relative;
    z-index: 1;
    overflow: hidden; }
    @media screen and (min-width: 1441px) {
      .offers__info {
        padding: 2.22222vw 0; } }
    @media screen and (max-width: 1440px) {
      .offers__info {
        padding: 3%; } }
    @media screen and (max-width: 899px) {
      .offers__info {
        padding: 5%; } }
  .offers__info-text {
    text-transform: uppercase;
    text-align: center;
    color: #1D2022;
    font-weight: 800; }
    @media screen and (min-width: 1441px) {
      .offers__info-text {
        max-width: 43.75vw;
        margin: 0 auto;
        font-size: 1.38889vw;
        line-height: 1.875vw; } }
    @media screen and (max-width: 1440px) {
      .offers__info-text {
        font-size: calc(16px + 4 * ((100vw - 479px) / 961));
        line-height: 1.4;
        max-width: 100%; } }
    @media screen and (max-width: 479px) {
      .offers__info-text {
        font-size: 16px; } }
  .offers__bg {
    position: absolute;
    top: -10%;
    left: 0;
    z-index: -1; }
  .offers__bg-row {
    white-space: nowrap; }
    .offers__bg-row:nth-of-type(even) {
      margin-left: -2.60417vw; }
  .offers__bg-img {
    margin-right: 10px;
    opacity: .2;
    pointer-events: none; }
    @media screen and (min-width: 1441px) {
      .offers__bg-img {
        width: 5.20833vw; } }

@media screen and (max-width: 767px) {
  .prizes {
    display: flex;
    flex-direction: column-reverse; } }

.prizes__left {
  position: relative;
  overflow: hidden; }

.prizes__photo-wrap {
  position: absolute;
  top: 9%;
  left: 40%; }
  @media screen and (min-width: 1441px) {
    .prizes__photo-wrap {
      width: 22.22222vw; } }
  @media screen and (max-width: 1440px) {
    .prizes__photo-wrap {
      width: 50%; } }
  @media screen and (max-width: 767px) {
    .prizes__photo-wrap {
      position: relative;
      height: 350px;
      width: 250px;
      left: 29%; } }
  @media screen and (max-width: 479px) {
    .prizes__photo-wrap {
      height: 200px;
      width: 165px; } }

.prizes__photo {
  width: 100%; }

.prizes__letters {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  text-transform: uppercase; }

@media screen and (min-width: 1441px) {
  .prizes__right {
    padding: 1.73611vw 11.38889vw 4.93056vw 2.22222vw; } }

@media screen and (max-width: 1440px) {
  .prizes__right {
    padding: 5% 3%; } }

@media screen and (max-width: 767px) {
  .prizes__right {
    padding: 5%; } }

@media screen and (min-width: 1441px) {
  .prizes__title {
    margin-bottom: 2.08333vw; } }

@media screen and (max-width: 1440px) {
  .prizes__title {
    margin-bottom: 30px; } }

.prizes__desc {
  color: #EDEDED;
  font-size: 16px;
  line-height: 22px; }
  @media screen and (min-width: 1441px) {
    .prizes__desc {
      font-size: 1.11111vw;
      line-height: 1.52778vw; } }

.prizes__list-title {
  margin-top: 14px;
  margin-bottom: 14px;
  color: #EDEDED;
  font-size: 16px;
  line-height: 22px;
  font-weight: 800; }
  @media screen and (min-width: 1441px) {
    .prizes__list-title {
      font-size: 1.11111vw;
      line-height: 1.52778vw;
      margin-top: 0.97222vw;
      margin-bottom: 0.97222vw; } }

.prizes__list {
  margin-bottom: 14px; }
  @media screen and (min-width: 1441px) {
    .prizes__list {
      margin-bottom: 0.97222vw; } }

.list__item {
  display: flex;
  align-items: center; }
  .list__item.active .list__text {
    color: #F2AE2E; }
  .list__item.active .fico {
    opacity: 1;
    font-size: 23px; }
    @media screen and (min-width: 1441px) {
      .list__item.active .fico {
        font-size: 1.59722vw; } }
  .list__item:not(:last-of-type) {
    margin-bottom: 12px; }
  @media screen and (min-width: 1441px) {
    .list__item {
      margin-bottom: 0.83333vw; } }

.list .fico {
  font-size: 17px;
  opacity: 0.4;
  margin-right: 13px;
  color: #F2AE2E; }
  @media screen and (min-width: 1441px) {
    .list .fico {
      margin-right: 0.90278vw;
      font-size: 1.18056vw; } }

.list__text {
  color: #EDEDED;
  font-size: 16px;
  line-height: 22px;
  font-weight: 800; }
  @media screen and (min-width: 1441px) {
    .list__text {
      font-size: 1.11111vw;
      line-height: 1.52778vw; } }

@media screen and (max-width: 767px) {
  .advantages {
    display: flex;
    flex-direction: column; } }

.advantages__right {
  position: relative;
  overflow: hidden; }

.advantages__photo-wrap {
  position: absolute;
  top: 9%;
  left: 27%; }
  @media screen and (min-width: 1441px) {
    .advantages__photo-wrap {
      width: 16.18056vw; } }
  @media screen and (max-width: 1440px) {
    .advantages__photo-wrap {
      width: 35%;
      min-width: 200px; } }
  @media screen and (max-width: 767px) {
    .advantages__photo-wrap {
      position: relative;
      min-width: 140px;
      width: 220px;
      height: 350px; } }
  @media screen and (max-width: 479px) {
    .advantages__photo-wrap {
      height: 200px;
      width: 140px; } }

.advantages__photo {
  width: 100%; }

.advantages__letters {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1; }

@media screen and (min-width: 1441px) {
  .advantages__left {
    padding: 1.73611vw 6.31944vw 4.93056vw 5.69444vw; } }

@media screen and (max-width: 1440px) {
  .advantages__left {
    padding: 5% 3%; } }

@media screen and (max-width: 767px) {
  .advantages__left {
    padding: 5%; } }

.advantages__title {
  text-transform: capitalize; }
  .advantages__title span {
    text-transform: uppercase; }
  @media screen and (min-width: 1441px) {
    .advantages__title {
      margin-bottom: 2.08333vw; } }
  @media screen and (max-width: 1440px) {
    .advantages__title {
      margin-bottom: 30px; } }

.advantages__list {
  margin-bottom: 35px; }
  @media screen and (min-width: 1441px) {
    .advantages__list {
      margin-bottom: 2.43056vw; } }

.advantages__btn {
  max-width: 290px; }
  @media screen and (min-width: 1441px) {
    .advantages__btn {
      max-width: 20.13889vw;
      font-size: 2.29167vw;
      line-height: 3.125vw;
      padding: 0.34722vw 1.38889vw; } }
  @media screen and (max-width: 479px) {
    .advantages__btn {
      margin: 0 auto; } }
