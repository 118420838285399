.advantages {
	@include r(767) {
		display: flex;
		flex-direction: column; }
	&__right {
		position: relative;
		overflow: hidden; }
	&__photo-wrap {
		position: absolute;
		top: 9%;
		left: 27%;
		@include rm(1441) {
			width: vw(233px); }
		@include r(1440) {
			width: 35%;
			min-width: 200px; }
		@include r(767) {
			position: relative;
			min-width: 140px;
			width: 220px;
			height: 350px; }
		@include r(479) {
			height: 200px;
			width: 140px; } }
	&__photo {
		width: 100%; }
	&__letters {
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: -1; }
	&__left {
		@include rm(1441) {
			padding: vw(25px) vw(91px) vw(71px) vw(82px); }
		@include r(1440) {
			padding: 5% 3%; }
		@include r(767) {
			padding: 5%; } }
	&__title {
		text-transform: capitalize;
		span {
			text-transform: uppercase; }
		@include rm(1441) {
			margin-bottom: vw(30px); }
		@include r(1440) {
			margin-bottom: 30px; } }
	&__list {
		margin-bottom: 35px;
		@include rm(1441) {
			margin-bottom: vw(35px); } }
	&__btn {
		max-width: 290px;
		@include rm(1441) {
			max-width: vw(290px);
			font-size: vw(33px);
			line-height: vw(45px);
			padding: vw(5px) vw(20px); }
		@include r(479) {
			margin: 0 auto; } } }
