.promo {
	overflow-x: hidden;
	padding-top: 52px;
	@include r(899) {
		padding-top: 48px; }
	@include r(767) {
		display: flex;
		flex-direction: column-reverse;
		position: relative; }
	@include r(479) {
		padding-top: 31px; }
	&__left {
		position: relative;
		overflow: hidden;
		@include rm(1441) {
			padding: vw(150px) 0;
			padding-right: vw(140px); }
		@include r(1440) {
			padding: 9% 9% 9% 4%; }
		@include r(767) {
			padding: 0% 5% 5% 5%;
			position: static; } }
	&__bg-text {
		position: absolute;
		span {
			padding: 0 30px; }
		@include rm(1441) {
			top: vw(-40px); }
		@include r(1440) {
			top: vw(-10px); }
		@include r(767) {
			position: static;
			transform: none; } }
	&__title {
		margin-bottom: vw(30px);
		@include r(767) {
			margin-bottom: 0;
			position: absolute;
			text-align: center;
			top: 90px;
			z-index: 1;
			left: 50%;
			transform: translateX(-50%);
			width: 90%;
			margin: 0 auto; }
		@include r(479) {
			width: 300px;
			top: 67px; } }
	&__desc {
		line-height: 1.4;
		margin-bottom: 13px;
		@include rm(1441) {
			font-size: vw(16px);
			line-height: vw(22px);
			margin-bottom: vw(13px); }
		@include r(1440) {
			line-height: 1.4;
			font-size: adft(16, 14); }
		@include r(479) {
			font-size: 14px; } }
	&__right {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		overflow: hidden;
		position: relative;
		@include r(479) {
			min-height: 500px; } }
	&__bg {
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		opacity: .3;
		@include rm(1441) {
			top: vw(40px); }
		@include r(1440) {
			top: 2%; } }
	&__bg-row {
		white-space: nowrap;
		@include rm(1441) {
			&:nth-of-type(even) {
				margin-left: vw(-75px/2); } }
		@include r(1440) {
			&:nth-of-type(even) {
				margin-left: -37.5px; } }
		@include r(479) {
			&:nth-of-type(even) {
				margin-left: -21.5px; } } }
	&__bg-img {
		opacity: 0;
		transform: scale(.9);
		@include rm(1441) {
			width: vw(75px);
			@include nlt {
				margin-right: vw(5px); } }
		@include r(1440) {
			width: 75px;
			@include nlt {
				margin-right: 5px; } }
		@include r(479) {
			width: 43px; } }
	&__picture {
		position: relative;
		margin: 0 auto;
		@include rm(1441) {
			width: vw(485px); }
		@include r(1440) {
			width: 64%;
			max-width: 100%; }
		@include r(767) {
			top: 100px;
			width: 500px; }
		@include r(640) {
			width: 420px; }
		@include r(479) {
			width: 290px;
			top: 80px; } }
	&__circle {
		width: 100%; }
	&__photo {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		@include rm(1440) {
			width: vw(448px); }
		@include r(1440) {
			width: 100%;
			max-width: 100%; } }
	&__btn {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, 35%);
		@include rm(1441) {
			max-width: vw(290px);
			font-size: vw(33px);
			line-height: vw(45px);
			padding: vw(5px) vw(20px); }
		@include r(1440) {
			max-width: inherit;
			width: auto; } }
	&__label {
		color: $grey;
		font-weight: 800;
		background: #121618;
		line-height: 1.2;
		padding: 5px;
		white-space: nowrap;
		text-transform: uppercase;
		em {
			text-transform: capitalize; }
		&--top {
			border-bottom: 1px solid #3E4448; }
		&--bottom {
			border-top: 1px solid #3E4448;
			span {
				transform: translateX(-400px);
				padding: 0 10px; } }
		span {
			display: inline-block;
			padding: 0 10px; }
		@include rm(1441) {
			font-size: vw(20px);
			padding: vw(5px);
			span {
				transform: translateX(vw(-400px)); } }
		@include r(1440) {
			font-size: adft(20, 16); }
		@include r(767) {
			z-index: 1;
			&--bottom {
				border-bottom: 1px solid #3E4448; } } } }
