.banner-info {
	background: $yellow;
	color: $light-yellow;
	font-weight: 800;
	text-transform: uppercase;
	white-space: nowrap;
	overflow: hidden;
	@include r(640) {
		padding: 5px 0; }
	&__text {
		padding-left: 10px;
		padding-right: 10px;
		@include rm(1441) {
			padding-left: vw(10px);
			padding-right: vw(10px); } } }
