/* open-sans-regular - cyrillic_cyrillic-ext_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/open-sans-v34-cyrillic_cyrillic-ext_latin_latin-ext-regular.woff2")
      format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("../fonts/open-sans-v34-cyrillic_cyrillic-ext_latin_latin-ext-regular.woff")
      format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-800 - cyrillic_cyrillic-ext_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/open-sans-v34-cyrillic_cyrillic-ext_latin_latin-ext-800.woff2")
      format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("../fonts/open-sans-v34-cyrillic_cyrillic-ext_latin_latin-ext-800.woff")
      format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-700 - cyrillic_cyrillic-ext_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/open-sans-v34-cyrillic_cyrillic-ext_latin_latin-ext-700.woff2")
      format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("../fonts/open-sans-v34-cyrillic_cyrillic-ext_latin_latin-ext-700.woff")
      format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
