.prizes {
	@include r(767) {
		display: flex;
		flex-direction: column-reverse; }
	&__left {
		position: relative;
		overflow: hidden; }
	&__photo-wrap {
		position: absolute;
		top: 9%;
		left: 40%;
		@include rm(1441) {
			width: vw(320px); }
		@include r(1440) {
			width: 50%; }
		@include r(767) {
			position: relative;
			height: 350px;
			width: 250px;
			left: 29%; }
		@include r(479) {
			height: 200px;
			width: 165px; } }
	&__photo {
		width: 100%; }
	&__letters {
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: -1;
		text-transform: uppercase; }
	&__right {
		@include rm(1441) {
			padding: vw(25px) vw(164px) vw(71px) vw(32px); }
		@include r(1440) {
			padding: 5% 3%; }
		@include r(767) {
			padding: 5%; } }
	&__title {
		@include rm(1441) {
			margin-bottom: vw(30px); }
		@include r(1440) {
			margin-bottom: 30px; } }
	&__desc {
		color: $light-grey;
		font-size: 16px;
		line-height: 22px;
		@include rm(1441) {
			font-size: vw(16px);
			line-height: vw(22px); } }
	&__list-title {
		margin-top: 14px;
		margin-bottom: 14px;
		color: $light-grey;
		font-size: 16px;
		line-height: 22px;
		font-weight: 800;
		@include rm(1441) {
			font-size: vw(16px);
			line-height: vw(22px);
			margin-top: vw(14px);
			margin-bottom: vw(14px); } }
	&__list {
		margin-bottom: 14px;
		@include rm(1441) {
			margin-bottom: vw(14px); } } }


.list {
	&__item {
		display: flex;
		align-items: center;
		&.active {
			.list__text {
				color: $yellow; }
			.fico {
				opacity: 1;
				font-size: 23px;
				@include rm(1441) {
					font-size: vw(23px); } } }
		@include nlt {
			margin-bottom: 12px; }
		@include rm(1441) {
			margin-bottom: vw(12px); } }
	.fico {
		font-size: 17px;
		opacity: 0.4;
		margin-right: 13px;
		color: $yellow;
		@include rm(1441) {
			margin-right: vw(13px);
			font-size: vw(17px); } }
	&__text {
		color: $light-grey;
		font-size: 16px;
		line-height: 22px;
		font-weight: 800;
		@include rm(1441) {
			font-size: vw(16px);
			line-height: vw(22px); } } }
