.header {
	background: $light-grey;
	z-index: 13;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	@include rm(1441) {
		padding: 10px vw(60px); }
	@include r(1440) {
		padding: 10px 4%; }
	@include r(899) {
		z-index: 22; }
	@include r(479) {
		padding: 5px 15px; }
	&__wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between; }
	&__logo {
		width: 103px;
		@include r(1199) {
			width: 90px; }
		@include r(479) {
			width: 65px; } }
	&__right {
		display: flex;
		align-items: center;
		@include r(899) {
			display: none; } }
	&__menu {
		margin-right: 36px;
		flex: 1;
		@include rm(1441) {
			margin-right: vw(36px); }
		@include r(1199) {
			margin-right: 17px; } }
	&__link {
		@include nlt {
			margin-right: 38px; }
		@include rm(1441) {
			@include nlt {
				margin-right: vw(38px); } }
		@include r(1199) {
			@include nlt {
				margin-right: 17px; } } }
	&__btn {
		margin-right: 36px;
		width: 135px;
		@include rm(1441) {
			margin-right: vw(36px); }
		@include r(1199) {
			margin-right: 17px; } } }


.menu-btn {
	display: none;
	@include r(899) {
		cursor: pointer;
		display: block;
		border: 1px solid $dark-grey;
		border-radius: 3px;
		color: $dark-grey;
		text-align: center;
		font-weight: 700;
		font-size: 16px;
		padding: 4px 11px; }
	@include r(479) {
		font-size: 12px;
		line-height: 1.2;
		padding: 1px 7px; } }
