@font-face {
	font-family: "fico";
	src: url('../fonts/icons/fico.eot');
	src: url('../fonts/icons/fico.eot?#iefix') format('eot'),
		url('../fonts/icons/fico.woff2') format('woff2'),
		url('../fonts/icons/fico.woff') format('woff'),
		url('../fonts/icons/fico.ttf') format('truetype'),
		url('../fonts/icons/fico.svg#fico') format('svg');
}

.fico:before {
	font-family: "fico";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	/* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
	text-decoration: none;
	text-transform: none;
}


.fico-arrow:before {
	content: "\E001";
}

.fico-next-arrow:before {
	content: "\E002";
}

.fico-prev-arrow:before {
	content: "\E003";
}

.fico-scull:before {
	content: "\E004";
}
