.statistics {
	overflow: hidden;
	@include r(767) {
		position: relative;
		padding-bottom: 530px; }
	@include r(479) {
		padding-bottom: 470px; }
	&__title {
		span {
			text-transform: capitalize; }
		@include r(767) {
			line-height: 120%; } }
	&__left {
		position: relative;
		overflow: hidden;
		background: $dark-grey;
		z-index: 1;
		&::before {
			content: '';
			display: block;
			position: absolute;
			background: url('../img/svg/scull.svg') no-repeat center;
			background-size: 100%;
			z-index: -1;
			transform: rotate(90deg);
			opacity: .2; }
		@include rm(1441) {
			padding-top: vw(76px);
			padding-bottom: vw(113px);
			padding-right: vw(32px);
			&::before {
				top: vw(-40px);
				left: vw(-10px);
				width: vw(473px);
				height: vw(422px); } }
		@include r(1440) {
			padding: 5% 2% 5% 4%;
			&::before {
				top: vw(-40px);
				left: vw(-10px);
				width: vw(473px);
				height: vw(422px); } }
		@include r(767) {
			overflow: hidden;
			padding: 5%;
			border-bottom: 1px solid $grey;
			&::before {
				width: 271px;
				height: 263px;
				top: -50px;
				left: -10px; } } }
	&__right {
		&--mob {
			display: none; }
		@include r(1440) {
			position: relative;
			left: -30px; }
		@include r(767) {
			position: absolute;
			left: 50%;
			transform: translate(-50%);
			text-align: center;
			&--desc {
				display: none; }
			&--mob {
				display: block; } } }
	&__row {
		color: $yellow;
		text-transform: uppercase;
		font-weight: 800;
		white-space: nowrap;
		margin-left: -2%;
		line-height: 107.5%;
		span {
			opacity: 0.05; }
		&:nth-of-type(even) {
			margin-left: -3%; }
		@include rm(1441) {
			font-size: vw(40px); }
		@include r(1440) {
			font-size: adft(38, 23); }
		@include r(1199) {
			font-size: 26px; }
		@include r(899) {
			font-size: 23px; }
		@include r(767) {
			font-size: 26px;
			&:nth-of-type(even) {
				margin-left: 0; }
			span {
				&.statistics__item {
					color: $light-grey;
					opacity: 1; } } }
		@include r(479) {
			font-size: 23px; } } }
