body {
	font-family: $baseFont;
	font-size: 16px;
	color: $light-grey;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscal;
	text-size-adjust: none;
	-moz-text-size-adjust: none;
	-webkit-text-size-adjust: none;
	-ms-text-size-adjust: none;
	background: $dark-grey;
	--aspect: 1; }

html {
	overflow-x: hidden;
	-ms-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%; }

::-ms-clear {
	display: none; }
*:focus, *:visited, *:active, *:hover {
	outline: 0!important; }
a, a:focus, a:visited, a:active, a:hover {
	outline: 0!important; }
*::-moz-focus-inner {
	border: 0;
	outline: none; }
img {
	max-width: 100%; }
ul, li {
	list-style: none; }

// Font icons
.fico {
	display: inline-block;
	&::before {
		display: block; } }


//grids
.container {
	width: 1270px;
	margin: 0 auto; }


.menu {
	font-weight: 700;
	display: flex;
	align-items: center;
	&__link {
		transition: color .3s;
		text-decoration: none;
		color: transparentize($dark-grey, .6);
		white-space: nowrap;
		&:hover {
			color: $dark-grey; }
		@include r(1199) {
			font-size: 14px; }
		@include r(899) {
			font-size: 16px;
			line-height: 22px;
			color: $dark-grey; } } }

.language {
	font-weight: 700;
	&__link {
		color: transparentize($dark-grey, .6);
		text-decoration: none;
		@include nlt {
			margin-right: 20px; }
		&.active {
			color: $dark-grey; }
		@include rm(1441) {
			@include nlt {
				margin-right: vw(20px); } }
		@include r(1199) {
			@include nlt {
				margin-right: 10px; } }
		@include r(899) {
			font-size: 16px;
			@include nlt {
				margin-right: 35px; } } } }

.btn {
	display: block;
	text-decoration: none;
	outline: none;
	max-width: 100%;
	width: 100%;
	text-align: center;
	text-transform: uppercase;
	color: $white;
	background: $orange;
	border-radius: 5px;
	font-weight: 800;
	padding: 3px 7px;
	font-family: $baseFont;
	line-height: 22px;
	transition: all .3s;
	white-space: nowrap;
	&--big {
		font-size: 33px;
		line-height: 45px;
		padding: 5px 20px; }
	.fico {
		color: $white;
		margin-left: 10px;
		font-size: 22px; }
	&:hover {
		background: #C23B00; }
	@include r(1440) {
		&--big {
			font-size: adft(33, 23);
			line-height: 1.4; } }
	@include r(479) {
		&--big {
			font-size: 23px; } } }



.section {
	@include rm(768) {
		display: flex;
		justify-content: center;
		&__col {
			width: 50%;
			@include nlt {
				border-right: 1px solid #3E4448; } } }
	&__col {
		@include rm(1441) {
			&:first-of-type {
				padding-left: vw(82px); } } } }



.title {
	font-weight: 800;
	line-height: 110%;
	text-transform: uppercase;
	color: $light-grey;
	@include rm(1441) {
		font-size: vw(40px); }
	@include r(1440) {
		font-size: adft(38, 23); }
	@include r(1199) {
		font-size: 26px; }
	@include r(899) {
		font-size: 23px; }
	@include r(767) {
		font-size: 26px; }
	@include r(479) {
		font-size: 23px; } }



.text-big {
	@include rm(641) {
		font-size: vw(55px);
		line-height: vw(85px); }
	@include r(1440) {
		font-size: adft(38, 23); }
	@include r(479) {
		font-size: 23px; } }


.big-marquee {
	font-weight: 800;
	line-height: 1;
	color: transparentize($yellow, .95);
	pointer-events: none;
	font-size: 623px;
	@include rm(1441) {
		font-size: vw(623px); }
	@include r(1440) {
		font-size: adft(623, 156); }
	@include r(479) {
		font-size: 156px; } }



.letters {
	pointer-events: none;
	&__row {
		opacity: 0;
		transform: translateY(80px) scale(1.4);
		white-space: nowrap;
		font-weight: 800;
		line-height: 95%;
		transform-origin: 33% 0;
		color: rgba(242, 174, 46, 0.05);
		&.mob {
			display: none; }
		@include rm(1441) {
			font-size: vw(40px); }
		@include r(1440) {
			font-size: adft(38, 23); }
		@include r(1199) {
			&.mob {
				display: block; } }
		@include r(479) {
			font-size: 23px; } } }


.footer {
	padding: vw(40px) 15px;
	text-align: center;
	border-top: 1px solid #3E4448;
	&__text {
		color: $grey;
		text-transform: uppercase;
		font-weight: 800;
		font-size: 20px; } }



.slick-arrow {
	position: absolute;
	bottom: -21px;
	cursor: pointer;
	z-index: 2;
	width: 42px;
	height: 42px;
	background: $light-grey;
	border: none;
	display: flex;
	align-items: center;
	&.slick-prev, &.slick-next {
		font-size: 22px; }
	&.slick-prev {
		left: 0;
		border-top-right-radius: 100px;
		border-bottom-right-radius: 100px; }
	&.slick-next {
		right: 0;
		border-top-left-radius: 100px;
		border-bottom-left-radius: 100px; } }
